/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/

@import "~antd/dist/antd.css";
@import "react-alice-carousel/lib/alice-carousel.css";

@font-face {
  font-family: "Ping AmL Light";
  src: url("./Fonts/PingAmL-Light.woff2") format("woff2"),
    url("./Fonts/PingAmL-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ping AmL Medium";
  src: url("./Fonts/PingAmL-Medium.woff2") format("woff2"),
    url("./Fonts/PingAmL-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ping AmL Bold";
  src: url("./Fonts/PingAmL-Bold.woff2") format("woff2"),
    url("./Fonts/PingAmL-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ping AmL Heavy";
  src: url("./Fonts/PingAmL-Heavy.woff2") format("woff2"),
    url("./Fonts/PingAmL-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ping LCG";
  src: url("./Fonts/PingLCG-Black.woff2") format("woff2"),
    url("./Fonts/PingLCG-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ping LCG";
  src: url("./Fonts/PingLCG-Bold.woff2") format("woff2"),
    url("./Fonts/PingLCG-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ping LCG";
  src: url("./Fonts/PingLCG-Light.woff2") format("woff2"),
    url("./Fonts/PingLCG-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ping LCG";
  src: url("./Fonts/PingLCG-Heavy.woff2") format("woff2"),
    url("./Fonts/PingLCG-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ping LCG";
  src: url("./Fonts/PingLCG-Regular.woff2") format("woff2"),
    url("./Fonts/PingLCG-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ping LCG";
  src: url("./Fonts/PingLCG-Medium.woff2") format("woff2"),
    url("./Fonts/PingLCG-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 40px 140px;
  background: transparent;
  z-index: 1000;
  -webkit-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  height: auto;
}

.header.invert-menu {
  background: white;
  position: fixed;
  padding: 10px 140px;
}

.ant-menu-item.main-menu-item.language-switch .ant-menu-title-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-item-align: self-start;
  -ms-grid-row-align: self-start;
  align-self: self-start;
  margin-top: -8px;
}

.language-switch ul {
  background: #ffffff;
  border-radius: 32px;
  height: 60px;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.language-switch ul:hover {
  height: 180px;
}

.language-switch:hover .not-active-language {
  opacity: 1;
  height: 60px;
}

.language {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background: #ffffff;
  line-height: 58px;
  color: #707070;
  font-family: "Ping LCG", "Ping AmL Bold";
  font-size: 15px;
  font-weight: 700;
  margin: auto;
}

.active-language {
  color: #ffc708;
}

.not-active-language {
  -webkit-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
  border-radius: 0%;
  background-color: transparent;
}

.section {
  padding: 32px 140px;
  overflow-x: hidden;
}

.ant-layout {
  background-color: #ffffff;
}

.ant-layout-footer {
  padding: 0;
}

.ant-layout-footer .section div.ant-typography,
.about-page .ant-layout-footer .section div.ant-typography,
.vision-page .ant-layout-footer .section div.ant-typography,
.library-page .ant-layout-footer .section div.ant-typography {
  font-size: 20px;
  font-weight: 500;
}

.ant-layout-footer .section div.ant-typography a,
.about-page .ant-layout-footer .section div.ant-typography a,
.vision-page .ant-layout-footer .section div.ant-typography a,
.library-page .ant-layout-footer .section div.ant-typography a {
  color: rgb(38, 38, 38);
}

.section h1.ant-typography {
  font-size: 60px;
  font-family: "Ping LCG", "Ping AmL Heavy";
  font-weight: 900;
  text-transform: uppercase;
  color: #ffffff;
}

.section h2.ant-typography {
  font-size: 45px;
  font-family: "Ping LCG", "Ping AmL Heavy";
  font-weight: 900;
  text-transform: uppercase;
  color: #ffffff;
}

footer .section h2.ant-typography {
  text-transform: uppercase !important;
  color: rgb(38, 38, 38) !important;
}

footer .section div.ant-typography {
  color: rgb(38, 38, 38) !important;
}

.section .not-found h1.ant-typography {
  color: rgb(38, 38, 38) !important;
}

.section .not-found div.ant-typography {
  color: rgb(38, 38, 38) !important;
}

.library-page .section h2.ant-typography {
  color: #293b97;
  text-transform: capitalize;
}

div.ant-typography,
.ant-typography p {
  margin-bottom: 12px;
}

.section h3.ant-typography {
  font-family: "Ping LCG", "Ping AmL Bold";
  color: #ffffff;
  font-weight: 700;
  font-size: 70px;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-text-orientation: sideways;
  text-orientation: sideways;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.section div.ant-typography,
.about-page .section div.big-text.ant-typography {
  font-size: 30px;
  font-family: "Ping LCG", "Ping AmL Bold";
  font-weight: 700;
  color: #ffffff;
}

.vision-page .section div.ant-typography {
  font-size: 22px;
  font-family: "Ping LCG", "Ping AmL Medium";
  font-weight: 500;
  color: #293b97;
}

.vision-page ol li,
.vision-page ul li {
  font-size: 22px;
  font-family: "Ping LCG", "Ping AmL Medium";
  font-weight: 500;
  color: #293b97;
}

.about-page .section div.ant-typography {
  font-size: 20px;
  font-family: "Ping LCG", "Ping AmL Medium";
  font-weight: 500;
  color: #ffffff;
}
.ant-modal-content {
  overflow-x: hidden;
}
.community-page {
  height: 100vh;
}
.community-page-inner {
  height: 100vh;
  overflow-y: scroll;
}
.community-page .section {
  min-height: 60%;
}

.community-page ul li {
  font-size: 22px;
  font-family: "Ping LCG", "Ping AmL Medium";
  font-weight: 500;
  color: #ffffff;
}

.library-page .section div.ant-typography {
  font-size: 16px;
  font-family: "Ping LCG", "Ping AmL Medium";
  font-weight: 500;
  color: #ffffff;
}

.about-page .ant-card-head-title,
.community-page .ant-card-head-title {
  font-size: 45px;
  font-family: "Ping LCG", "Ping AmL Heavy";
  font-weight: 900;
  text-transform: uppercase;
  color: #ffffff;
}

.about-page .section .team-card div.ant-typography {
  font-size: 18px;
  font-family: "Ping LCG", "Ping AmL Medium";
  font-weight: 500;
  color: #ffffff;
}

.about-page .section .partners div.ant-typography {
  font-size: 20px;
  font-family: "Ping LCG", "Ping AmL Medium";
  font-weight: 500;
  color: #262626;
}

.about-page .ant-card-head {
  border-bottom: none;
}

.vision-page .section h2.ant-typography {
  color: #293b97;
  text-transform: capitalize;
}

.white-text.section h2.ant-typography {
  color: #ffffff;
  text-transform: capitalize;
}
.white-text.section div.ant-typography {
  color: #ffffff;
}

.community-page h2.ant-typography {
  color: #5a4a76;
}
.community-page div.ant-typography {
  color: #5a4a76;
  font-size: 22px;
  font-family: "Ping LCG", "Ping AmL Medium";
  font-weight: 500;
}
.community-page h4.ant-typography {
  font-family: "Ping LCG", "Ping AmL Bold";
  color: #ffffff;
  font-size: 70px;
  font-weight: 700;
}

.ant-card-meta-title {
  font-family: "Ping LCG", "Ping AmL Bold";
  font-size: 18px;
  font-weight: 700;
  color: #1a1a1a;
  -webkit-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

.ant-card-meta-title a {
  color: #1a1a1a;
}

.blog-excerpt .ant-card-meta-title {
  font-size: 30px;
  font-weight: 900;
  color: #1a1a1a;
  -webkit-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

.blog-modal-wrap .ant-modal-close {
  color: #262626;
  top: 5vh;
}
.blog-modal-wrap .ant-card-cover {
  max-width: 70%;
  margin: auto;
}

.ant-card-meta-detail {
  text-align: center;
}

.ant-card-meta-title:hover {
  color: #49a8e4;
  cursor: pointer;
}

.blog-image:hover {
  cursor: pointer;
}

.read-more {
  text-transform: uppercase;
  font-weight: 700;
  color: #49a8e4;
}

.read-more:hover {
  cursor: pointer;
}

.ant-card-meta-title:hover a {
  color: #49a8e4;
}

.ant-card-meta-description {
  color: #333333;
}

.blog-excerpt {
  background: white;
}

.blog-excerpt .ant-card-meta-detail {
  text-align: left;
}

.blog-excerpt .ant-card-cover {
  max-width: 90%;
  margin: auto;
}

.blog-archive .ant-spin-container {
  background: white;
  -webkit-transition: opacity 0.7s;
  -o-transition: opacity 0.7s;
  transition: opacity 0.7s;
}
.blog-archive .section {
  background: white;
  margin: 8% auto 0;
  min-height: auto;
}

.alice-carousel__prev-btn {
  position: absolute;
  left: -16px;
  top: 25%;
  z-index: 2;
  text-align: left;
  background: #ffffff;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  line-height: 4px;
  color: #262626;
  font-size: 24px;
  -webkit-box-shadow: 0 1px 3px rgb(0 0 0 / 8%), 0 1px 2px rgb(0 0 0 / 10%);
  box-shadow: 0 1px 3px rgb(0 0 0 / 8%), 0 1px 2px rgb(0 0 0 / 10%);
}

.alice-carousel__next-btn {
  position: absolute;
  right: -10px;
  top: 25%;
  z-index: 2;
  text-align: right;
  background: #ffffff;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  line-height: 4px;
  color: #262626;
  font-size: 24px;
  -webkit-box-shadow: 0 1px 3px rgb(0 0 0 / 8%), 0 1px 2px rgb(0 0 0 / 10%);
  box-shadow: 0 1px 3px rgb(0 0 0 / 8%), 0 1px 2px rgb(0 0 0 / 10%);
}

.library-page .alice-carousel__prev-btn {
  left: 16px;
  top: 50%;
}

.library-page .alice-carousel__next-btn  {
  right: 16px;
  top: 50%;
}

.section {
  min-height: 100vh;
  font-family: "Ping LCG", "Ping AmL Light";
  font-weight: 500;
}

.logo {
  margin: auto;
}
.logo img {
  width: 242px;
}

.invert-menu .logo img {
  width: 190px;
}

.ant-menu-item {
  padding: 0;
  text-align: center;
}

.ant-menu-inline .ant-menu-item {
  overflow: visible;
}

.ant-menu-dark .ant-menu-item > span > a {
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 36px;
  font-family: "Ping LCG", "Ping AmL Light";
  font-weight: 300;
  -webkit-transition: font-size 500ms ease-in-out;
  -o-transition: font-size 500ms ease-in-out;
  transition: font-size 500ms ease-in-out;
}

.invert-menu .ant-menu-dark .ant-menu-item > span > a {
  font-size: 1.2rem;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
  -o-text-overflow: unset;
  text-overflow: unset;
  overflow: unset;
}

.ant-menu-item-selected .ant-menu-title-content > a::after,
.ant-menu-title-content > a:after {
  content: "";
  position: absolute;
  z-index: -1;
  right: 0;
  width: 0;
  bottom: -2px;
  height: 2px;
  -webkit-transition-property: width;
  -o-transition-property: width;
  transition-property: width;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.ant-menu-item-selected .ant-menu-title-content a > .underline-style,
.ant-menu-title-content a:hover > .underline-style,
.ant-menu-title-content a:focus > .underline-style,
.ant-menu-title-content a:active > .underline-style {
  left: 0 !important;
  right: auto !important;
  width: 100% !important;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: transparent;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.main-menu-item {
  padding-left: 0;
  padding-right: 0;
  margin: 0 12px;
}

.read-more-btn {
  font-size: 20px;
  font-family: "Ping LCG", "Ping AmL Heavy";
  font-weight: 900;
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ant-modal {
  position: absolute;
  top: 0;
  width: auto;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 0;
  height: 100vh;
}

.ant-modal-body {
  padding: 0;
}

.ant-modal-footer {
  display: none;
}

.ant-modal-close {
  top: 25vh;
  right: 140px;
  color: rgb(253 253 253);
  z-index: 100;
}

.ant-modal-close-x {
  font-size: 35px;
}

.communities {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.communities {
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: cover;
  -webkit-transition: background-image 300ms ease-in-out;
  -o-transition: background-image 300ms ease-in-out;
  transition: background-image 300ms ease-in-out;
}

.geghamavan {
  background-image: url("./Assets/geghamavan-color.png");
}

.geghamavan:hover {
  background-image: url("./Assets/geghamavan-vert.webp");
}

.varser {
  background-image: url("./Assets/varser-color.png");
}

.varser:hover {
  background-image: url("./Assets/varser-vert.webp");
}

.zovaber {
  background-image: url("./Assets/zovaber-color.png");
}

.zovaber:hover {
  background-image: url("./Assets/zovaber-vert.webp");
}

.tsaghkunk {
  background-image: url("./Assets/tsaghkunk-color.png");
}

.tsaghkunk:hover {
  background-image: url("./Assets/tsaghkunk-vert.webp");
}

.ddmashen {
  background-image: url("./Assets/ddmashen-color.png");
}

.ddmashen:hover {
  background-image: url("./Assets/ddmashen-vert.webp");
}

.gagarin {
  background-image: url("./Assets/gagarin-color.png");
}

.gagarin:hover {
  background-image: url("./Assets/gagarin-vert.webp");
}

.section div.ant-typography.vision-title {
  font-size: 18rem;
  font-weight: 900;
  white-space: nowrap;
  margin-bottom: 0;
  line-height: initial;
}

.vision-circle {
  position: absolute;
  right: 0;
  width: 100vh;
  height: 100vh;
  background-color: #8f78b6;
  border-radius: 50%;
}

/* page transitions */
.page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.page-enter {
  z-index: 2;
}

.page-enter-active {
  -webkit-animation: moveFromRightFade 0.7s cubic-bezier(0.4, 0.1, 0.7, 0.95);
  animation: moveFromRightFade 0.7s cubic-bezier(0.4, 0.1, 0.7, 0.95);
}

.page-exit-active {
  -webkit-animation: moveToLeftFade50 0.7s cubic-bezier(0.4, 0.1, 0.7, 0.95);
  animation: moveToLeftFade50 0.7s cubic-bezier(0.4, 0.1, 0.7, 0.95);
}

.modal-wrap {
  -webkit-animation: moveFromRightFade 0.5s cubic-bezier(0.4, 0.1, 0.7, 0.95);
  animation: moveFromRightFade 0.5s cubic-bezier(0.4, 0.1, 0.7, 0.95);
}

.modal-wrap-exit-active {
  -webkit-animation: movetoRightFade 0.6s cubic-bezier(0.4, 0.1, 0.7, 0.95);
  animation: movetoRightFade 0.6s cubic-bezier(0.4, 0.1, 0.7, 0.95);
}

@-webkit-keyframes moveFromRightFade {
  from {
    scale: 0.5;
    opacity: 1;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes moveFromRightFade {
  from {
    scale: 0.5;
    opacity: 1;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@-webkit-keyframes movetoRightFade {
  to {
    scale: 0.5;
    opacity: 1;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@keyframes movetoRightFade {
  to {
    scale: 0.5;
    opacity: 1;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@-webkit-keyframes moveToLeftFade50 {
  to {
    scale: 0.5;
    opacity: 0.5;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
@keyframes moveToLeftFade50 {
  to {
    scale: 0.5;
    opacity: 0.5;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

/* end of page transitions */

.community-title {
  position: absolute;
  z-index: 99;
  top: 110px;
  left: 20px;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: none;
}

.barsBtn {
  display: block;
  width: 25px;
  height: 3px;
  background: #8f78b6;
  position: relative;
}
.ant-btn.ant-btn-primary.barsMenu {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
}
.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 25px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 3px;
  background: #8f78b6;
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.desktop-logo {
  display: block;
}

.mobile-logo {
  display: none;
}

.desktop-menu {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.section.home-section-01 {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
}

.alice-carousel__stage-item img {
  line-height: initial;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100vh;
}

.blog-archive .alice-carousel__stage-item img {
  height: auto;
}

.st0 {
  opacity: 0;
  cursor: pointer;
  -webkit-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  transition: opacity 300ms;
}

.map-image {
  overflow: visible;
}

.geghamavan > .st1 {
  fill: #8f78b6;
}

.varser > .st1 {
  fill: #ef4b23;
}

.zovaber > .st1 {
  fill: #f49fc4;
}

.tsaghkunk > .st1 {
  fill: #009547;
}

.ddmashen > .st1 {
  fill: #ffc708;
}
.gagarin > .st1 {
  fill: #293b97;
}

.st0:hover {
  opacity: 0.5;
}

#Layer_1 {
  enable-background: new 0 0 1920 1080;
  -ms-flex-item-align: start;
  align-self: flex-start;
}
.vision-section {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 0;
}

.first-circle-image {
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right bottom;
}

.main-directions {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
}

.arrow-left {
  color: #ffffff;
  font-size: 50px;
  z-index: 999;
}

.arrow-right {
  color: #ffffff;
  font-size: 50px;
  z-index: 999;
}

.mobile-arrows {
  display: none;
}

.desktop-arrows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 25vh;
  width: 100%;
  z-index: 99;
}

.desktop-arrows .arrow-right {
  right: 32px;
  margin-right: 32px;
}

.desktop-arrows .arrow-left {
  left: 32px;
  margin-left: 32px;
}

.mvrdv {
  background-size: cover;
  min-height: 70vh;
}

@media only screen and (min-width: 360px) and (max-width: 991px) {
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0 24px;
    background: #ffffff;
    z-index: 1000;
    height: 80px;
  }
  .header.invert-menu {
    padding: 0 24px;
  }
  .logo img {
    width: 140px;
  }

  .language {
    display: inline;
    margin-right: 15px;
  }
  .not-active-language {
    opacity: 1;
  }

  .alice-carousel__stage-item img {
    -o-object-fit: cover;
    object-fit: cover;
  }

  .invert-menu .logo img {
    width: 140px;
  }
  .desktop-logo {
    display: none;
  }
  .mobile-logo {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .barsMenu {
    display: inline-block;
  }
  .desktop-menu {
    display: none;
  }

  .section {
    padding: 6px 24px;
    min-height: 80vh;
  }

  .blog-archive .section {
    margin: 20% auto 0;
  }

  .communities {
    height: 98px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .communities:first-child {
    height: 200px;
    display: block;
  }

  .read-more-btn {
    font-size: 12px;
  }

  .section h2.ant-typography {
    font-size: 23px;
  }

  .section div.ant-typography,
  .about-page .section div.big-text.ant-typography {
    font-size: 18px;
  }

  .section h1.ant-typography {
    font-size: 23px;
  }

  .section h3.ant-typography {
    color: #ffffff;
    font-weight: 700;
    font-size: 30px;
    -webkit-writing-mode: horizontal-tb;
    -ms-writing-mode: lr-tb;
    writing-mode: horizontal-tb;
    -webkit-text-orientation: mixed;
    text-orientation: mixed;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .section div.ant-typography.vision-title {
    font-size: 164px;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    -webkit-text-orientation: sideways;
    text-orientation: sideways;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .section h2.ant-typography.vision-subtitle {
    line-height: initial;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    -webkit-text-orientation: sideways;
    text-orientation: sideways;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    margin: 0;
  }

  .community-page h4.ant-typography {
    font-size: 30px;
  }

  .library-page .section div.ant-typography,
  .community-page ul li {
    font-size: 16px;
  }

  .about-page .section div.ant-typography {
    font-size: 14px;
  }

  .vision-page .section div.ant-typography {
    font-size: 14px;
  }

  .vision-page ol li,
  .vision-page ul li {
    font-size: 14px;
  }

  .library-page .section div.ant-typography {
    font-size: 14px;
  }

  .about-page .section .team-card div.ant-typography {
    font-size: 14px;
  }

  .vision-circle {
    display: none;
  }

  .community-section {
    display: block;
    min-height: auto;
  }

  .section.community-section h3.ant-typography {
    margin-left: 24px;
  }

  .community-title {
    position: relative;
    top: 0;
    left: 0;
    padding: 16px;
  }
  .ant-drawer-header .ant-drawer-close {
    color: #8f78b6;
    font-size: 24px;
  }
  .ant-drawer-header {
    border-bottom: 3px solid #8f78b6;
  }
  .ant-drawer-body .ant-menu-item.ant-menu-item-only-child {
    border-bottom: 3px solid #8f78b6;
  }
  .section.home-section-01 {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
  }
  .vision-section {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-bottom: 32px;
  }
  .vision-section .read-more-btn {
    float: left;
    position: absolute;
    left: 0;
  }
  .anticon-down {
    display: none;
  }
  .vision-title-subtitle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .first-circle-image {
    background-size: contain;
  }
  .main-directions {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
  }
  #Layer_1 {
    position: relative;
    left: 0;
    max-width: 1092px;
    height: 496px;
  }

  .ant-modal-close {
    top: 0;
    right: 0;
  }

  .ant-modal-close-x {
    font-size: 35px;
  }

  .mobile-arrows {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .desktop-arrows {
    display: none;
  }
  .main-menu-item {
    padding-left: 0;
    padding-right: 0;
    margin: 12px 12px;
  }
  /*animation */
  .page-enter {
    opacity: 0;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-animation: none;
    animation: none;
  }
  .page-enter-active {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    -o-transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
    -webkit-animation: none;
    animation: none;
  }
  .page-exit {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-animation: none;
    animation: none;
  }
  .page-exit-active {
    opacity: 0;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    -o-transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
    -webkit-animation: none;
    animation: none;
  }

  .modal-wrap {
    -webkit-animation: none;
    animation: none;
  }

  .modal-wrap-exit-active {
    -webkit-animation: none;
    animation: none;
  }

  .ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark li:last-child {
    border-bottom: none;
  }

  .library-item {
    margin-bottom: 12px;
  }
  .mvrdv {
    min-height: 20vh;
  }
  .library-page,
  .vision-page {
    margin-top: 90px;
  }
  .main-directions {
    padding: 6px 24px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 991px) and (orientation : landscape) { 
  .mvrdv {
    min-height: 70vh;
  }
}


@media only screen and (min-width: 992px) and (max-width: 1365px) {
  .header {
    padding: 40px 70px;
  }

  .header.invert-menu {
    background: white;
    position: fixed;
    padding: 10px 30px;
  }

  .logo img {
    width: 140px;
  }

  .section {
    padding: 6px 24px;
    min-height: 100vh;
  }

  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item {
    padding: 0 18px;
  }

  .ant-menu-dark .ant-menu-item > span > a {
    font-size: 18px;
    line-height: 16px;
  }

  .read-more-btn {
    font-size: 12px;
  }

  .section h2.ant-typography {
    font-size: 23px;
  }

  .communities {
    height: 100vh;
  }

  .section div.ant-typography,
  .about-page .section div.big-text.ant-typography {
    font-size: 18px;
  }

  .section div.ant-typography.vision-title {
    font-size: 250px;
  }

  .community-page h4.ant-typography {
    font-size: 30px;
  }

  .library-page .section div.ant-typography,
  .community-page ul li {
    font-size: 16px;
  }

  .about-page .section div.ant-typography {
    font-size: 14px;
  }

  .vision-page .section div.ant-typography {
    font-size: 14px;
  }

  .vision-page ol li,
  .vision-page ul li {
    font-size: 14px;
  }

  .library-page .section div.ant-typography {
    font-size: 14px;
  }

  .about-page .section .team-card div.ant-typography {
    font-size: 14px;
  }

  .vision-circle {
    display: none;
  }

  .section.community-section h3.ant-typography {
    margin-left: 24px;
  }
  .ant-drawer-header .ant-drawer-close {
    color: #8f78b6;
    font-size: 24px;
  }
  .ant-drawer-header {
    border-bottom: none solid #8f78b6;
  }
  .ant-drawer-body .ant-menu-item.ant-menu-item-only-child {
    border-bottom: 1px solid #8f78b6;
  }
  .vision-section {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-bottom: 32px;
  }

  .first-circle-image {
    background-size: contain;
  }
  .main-directions {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
  }
  #Layer_1 {
    position: relative;
    left: 0;
    max-width: 100%;
    height: auto;
  }

  .ant-modal-close {
    top: 0;
    right: 0;
  }

  .ant-modal-close-x {
    font-size: 35px;
  }
}

@media only screen and (min-width: 1365px) and (max-width: 1440px) {
  .header {
    padding: 40px 24px;
  }

  .header.invert-menu {
    padding: 10px 24px;
  }

  .section {
    padding: 6px 24px;
    min-height: 100vh;
  }

  .section div.ant-typography,
  .about-page .section div.big-text.ant-typography {
    font-size: 32px;
  }

  .section h1.ant-typography {
    font-size: 50px;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  .page-enter {
    opacity: 0;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-animation: none;
    animation: none;
  }
  .page-enter-active {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    -o-transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
    -webkit-animation: none;
    animation: none;
  }
  .page-exit {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-animation: none;
    animation: none;
  }
  .page-exit-active {
    opacity: 0;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    -o-transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
    -webkit-animation: none;
    animation: none;
  }

  .modal-wrap {
    -webkit-animation: none;
    animation: none;
  }

  .modal-wrap-exit-active {
    -webkit-animation: none;
    animation: none;
  }
}
